var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"modal fade",staticStyle:{"overflow":"auto"},attrs:{"id":"modal-form-tipo-servicio"}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(_vm._s(_vm.accion)+" Tipo Servicio")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":""}},[_vm._v("Tipo Servicio")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_servicio),expression:"tipoServSolicitud.tipo_servicio"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_servicio.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"tipo_servicio"},on:{"input":function($event){return _vm.changeTipoVehi()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_servicio", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_servicios),function(tipo_servicio){return _c('option',{key:tipo_servicio.numeracion,domProps:{"value":tipo_servicio.numeracion}},[_vm._v(" "+_vm._s(tipo_servicio.descripcion)+" ")])})],2)]),(_vm.tipoServSolicitud.tipo_servicio)?_c('div',{staticClass:"form-group col-md-2"},[(_vm.tipoServSolicitud.tipo_servicio == 1)?_c('div',[_c('label',{attrs:{"for":""}},[_vm._v("Tipo Vehículos")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_vh_equi_obj),expression:"tipoServSolicitud.tipo_vh_equi_obj"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"id":"tipo_vh_equi"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_vh_equi_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_vehiculos),function(tipo_vehiculo,index){return _c('option',{key:index,domProps:{"value":tipo_vehiculo}},[_vm._v(" "+_vm._s(tipo_vehiculo.nombre)+" ")])})],2)]):(_vm.tipoServSolicitud.tipo_servicio == 2)?_c('div',[_c('label',{attrs:{"for":""}},[_vm._v("Tipo Equipos")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_vh_equi_obj),expression:"tipoServSolicitud.tipo_vh_equi_obj"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"id":"tipo_vh_equi"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_vh_equi_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_equipos),function(tipo_equipo){return _c('option',{key:tipo_equipo.numeracion,domProps:{"value":tipo_equipo}},[_vm._v(" "+_vm._s(tipo_equipo.descripcion)+" ")])})],2)]):(_vm.tipoServSolicitud.tipo_servicio == 3)?_c('div',[_c('label',{attrs:{"for":""}},[_vm._v("Complementarios")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_vh_equi_obj),expression:"tipoServSolicitud.tipo_vh_equi_obj"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"id":"tipo_vh_equi"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_vh_equi_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_complementos),function(tipo_comple){return _c('option',{key:tipo_comple.numeracion,domProps:{"value":tipo_comple}},[_vm._v(" "+_vm._s(tipo_comple.descripcion)+" ")])})],2)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":""}},[_vm._v("Día / Viaje")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_cantidad),expression:"tipoServSolicitud.tipo_cantidad"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_cantidad.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"tipo_cantidad"},on:{"input":_vm.$v.tipoServSolicitud.tipo_cantidad.$touch,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_cantidad", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Día")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Viaje")])])]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":""}},[_vm._v("Cant Vehículos Solicitar")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.cantidad_vehiculos),expression:"tipoServSolicitud.cantidad_vehiculos"}],staticClass:"form-control form-control-sm p-2",class:_vm.$v.tipoServSolicitud.cantidad_vehiculos.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"cantidad_vehiculos","type":"number"},domProps:{"value":(_vm.tipoServSolicitud.cantidad_vehiculos)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.tipoServSolicitud, "cantidad_vehiculos", $event.target.value)},_vm.$v.tipoServSolicitud.cantidad_vehiculos.$touch]}})]),_c('div',{staticClass:"form-group col-md-2"},[(_vm.tipoServSolicitud.tipo_cantidad == 1)?_c('label',{attrs:{"for":""}},[_vm._v("Días "),_c('small',[_vm._v(" (Tiempo) ")])]):_c('label',{attrs:{"for":""}},[_vm._v("Viajes "),_c('small',[_vm._v(" (Cant) ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.cantidad),expression:"tipoServSolicitud.cantidad"}],staticClass:"form-control form-control-sm p-2",class:_vm.$v.tipoServSolicitud.cantidad.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"cantidad_vehiculos","type":"number"},domProps:{"value":(_vm.tipoServSolicitud.cantidad)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.tipoServSolicitud, "cantidad", $event.target.value)},_vm.$v.tipoServSolicitud.cantidad.$touch]}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"tipo_solicitud"}},[_vm._v("Transporta Material")]),_c('small',{staticStyle:{"font-size":"0.7em"}},[_vm._v(" (Inventario)")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.material_inventarios),expression:"tipoServSolicitud.material_inventarios"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.material_inventarios.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"material_inventarios"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "material_inventarios", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Si")]),_c('option',{attrs:{"value":"2"}},[_vm._v("No")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Observación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.observaciones),expression:"tipoServSolicitud.observaciones"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.observaciones.$invalid
                    ? 'is-invalid'
                    : 'is-valid',domProps:{"value":(_vm.tipoServSolicitud.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tipoServSolicitud, "observaciones", $event.target.value)}}})])]),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm text-xs"},[_c('thead',{staticClass:"bg-gray text-center"},[_c('tr',[_c('th',{staticClass:"text-nowrap"},[_vm._v(" Acciones "),_c('button',{staticClass:"btn btn-sm bg-primary text-white",staticStyle:{"cursor":"pointer"},attrs:{"type":"button","disabled":_vm.estado_ruta == 1 ||
                            !_vm.$parent.form.id ||
                            _vm.$parent.form.estado == 2 ||
                            _vm.$parent.form.estado == 3 ||
                            _vm.$parent.form.estado == 4},on:{"click":function($event){return _vm.addRuta()}}},[_c('i',{staticClass:"fas fa-plus"})])]),_c('th',{staticClass:"text-center"},[_vm._v("Tipo Sitio")]),_c('th',{staticClass:"text-center"},[_vm._v("Sitio")]),_c('th',{staticClass:"text-center"},[_vm._v("Direccion")]),_c('th',{staticClass:"text-center"},[_vm._v("Nombre de Contacto")]),_c('th',{staticClass:"text-center"},[_vm._v("Número de Contacto")]),_c('th',{staticClass:"text-center"},[_vm._v("Correo")])])]),_c('tbody',{attrs:{"id":"tbody"}},[_vm._l((_vm.rutas),function(rut,index){return _c('tr',{key:rut.id},[_c('td',{staticClass:"text-center"},[(_vm.estado_ruta != 2)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":_vm.estado_ruta == 1 ||
                              !_vm.$parent.form.id ||
                              _vm.$parent.form.estado == 2 ||
                              _vm.$parent.form.estado == 3 ||
                              _vm.$parent.form.estado == 4},on:{"click":function($event){return _vm.editRuta(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":_vm.estado_ruta == 1 ||
                              !_vm.$parent.form.id ||
                              _vm.$parent.form.estado == 2 ||
                              _vm.$parent.form.estado == 3 ||
                              _vm.$parent.form.estado == 4},on:{"click":function($event){return _vm.destroyRuta(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(_vm.estado_ruta == 2 && _vm.id_actualizar_ruta == index)?_c('div',{staticClass:"btn-group"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.ruta.$invalid),expression:"!$v.ruta.$invalid"}],staticClass:"btn bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveUpdateRuta(index)}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelRuta()}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                          _vm.estado_ruta == 2 && _vm.id_actualizar_ruta !== index
                        )?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":""},on:{"click":function($event){return _vm.editRuta(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":""},on:{"click":function($event){return _vm.destroyRuta(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]),_c('td',{staticClass:"text-center"},[(
                          _vm.id_actualizar_ruta === index && _vm.estado_ruta === 2
                        )?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.tipo_sitio),expression:"ruta.tipo_sitio"}],staticClass:"form-control form-control-sm p-0",class:_vm.$v.ruta.tipo_sitio.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"placeholder":"Tipo sitio","label":"nombres"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.ruta, "tipo_sitio", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Origen")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Destino")])])]):_c('div',[_c('span',{staticClass:"badge",class:rut.tipo_sitio == 1 ? 'bg-success' : 'bg-primary'},[_vm._v(" "+_vm._s(rut.tipo_sitio == 1 ? "Origen" : "Destino")+" ")])])]),_c('td',{staticClass:"text-center"},[(
                          _vm.id_actualizar_ruta === index && _vm.estado_ruta === 2
                        )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.nombre),expression:"ruta.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.nombre.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","id":"nombre"},domProps:{"value":(_vm.ruta.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "nombre", $event.target.value)}}})]):_c('div',[_vm._v(_vm._s(rut.nombre))])]),_c('td',{staticClass:"text-center"},[(
                          _vm.id_actualizar_ruta === index && _vm.estado_ruta === 2
                        )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.direccion),expression:"ruta.direccion"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.direccion.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","id":"direccion"},domProps:{"value":(_vm.ruta.direccion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "direccion", $event.target.value)}}})]):_c('div',[_vm._v(_vm._s(rut.direccion))])]),_c('td',{staticClass:"text-center"},[(
                          _vm.id_actualizar_ruta === index && _vm.estado_ruta === 2
                        )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.nombre_contacto),expression:"ruta.nombre_contacto"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.nombre_contacto.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","id":"nombre_contacto"},domProps:{"value":(_vm.ruta.nombre_contacto)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "nombre_contacto", $event.target.value)}}})]):_c('div',[_vm._v(_vm._s(rut.nombre_contacto))])]),_c('td',{staticClass:"text-center"},[(
                          _vm.id_actualizar_ruta === index && _vm.estado_ruta === 2
                        )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.telefono_contacto),expression:"ruta.telefono_contacto"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.telefono_contacto.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"number","id":"telefono_contacto"},domProps:{"value":(_vm.ruta.telefono_contacto)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "telefono_contacto", $event.target.value)}}})]):_c('div',[_vm._v(_vm._s(rut.telefono_contacto))])]),_c('td',{staticClass:"text-center"},[(
                          _vm.id_actualizar_ruta === index && _vm.estado_ruta === 2
                        )?_c('div',{staticClass:"input-group mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.email_contacto),expression:"ruta.email_contacto"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.email_contacto.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"email","id":"email_contacto","placeholder":"Email"},domProps:{"value":(_vm.ruta.email_contacto)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "email_contacto", $event.target.value)}}}),_vm._m(1,true)]):_c('div',[_vm._v(_vm._s(rut.email_contacto))])])])}),(_vm.estado_ruta === 1)?_c('tr',[_c('td',[_c('div',{staticClass:"btn-group float-right"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.ruta.$invalid),expression:"!$v.ruta.$invalid"}],staticClass:"btn bg-success",on:{"click":function($event){return _vm.saveRuta()}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelRuta()}}},[_c('i',{staticClass:"fas fa-trash"})])])]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.tipo_sitio),expression:"ruta.tipo_sitio"}],staticClass:"form-control form-control-sm p-0",class:_vm.$v.ruta.tipo_sitio.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"placeholder":"Tipo sitio","label":"nombres"},on:{"input":_vm.$v.ruta.tipo_sitio.$touch,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.ruta, "tipo_sitio", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Origen")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Destino")])]),(
                          (!_vm.$v.ruta.tipo_sitio.$dirty ||
                            _vm.$v.ruta.tipo_sitio.$invalid) &&
                            (_vm.ruta.tipo_sitio == '' || _vm.ruta.tipo_sitio == null)
                        )?_c('div',[_vm._m(2)]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.nombre),expression:"ruta.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.nombre.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"text","id":"nombre"},domProps:{"value":(_vm.ruta.nombre)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "nombre", $event.target.value)},_vm.$v.ruta.nombre.$touch]}}),(
                          (!_vm.$v.ruta.nombre.$dirty ||
                            _vm.$v.ruta.nombre.$invalid) &&
                            (_vm.ruta.nombre == '' || _vm.ruta.nombre == null)
                        )?_c('div',[_vm._m(3)]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.direccion),expression:"ruta.direccion"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.direccion.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","id":"direccion"},domProps:{"value":(_vm.ruta.direccion)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "direccion", $event.target.value)},_vm.$v.ruta.direccion.$touch]}}),(
                          (!_vm.$v.ruta.direccion.$dirty ||
                            _vm.$v.ruta.direccion.$invalid) &&
                            (_vm.ruta.direccion == '' || _vm.ruta.direccion == null)
                        )?_c('div',[_vm._m(4)]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.nombre_contacto),expression:"ruta.nombre_contacto"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.nombre_contacto.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","id":"nombre_contacto"},domProps:{"value":(_vm.ruta.nombre_contacto)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "nombre_contacto", $event.target.value)},_vm.$v.ruta.nombre_contacto.$touch]}}),(
                          (!_vm.$v.ruta.nombre_contacto.$dirty ||
                            _vm.$v.ruta.nombre_contacto.$invalid) &&
                            (_vm.ruta.nombre_contacto == '' ||
                              _vm.ruta.nombre_contacto == null)
                        )?_c('div',[_vm._m(5)]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.telefono_contacto),expression:"ruta.telefono_contacto"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.telefono_contacto.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"number","id":"telefono_contacto"},domProps:{"value":(_vm.ruta.telefono_contacto)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "telefono_contacto", $event.target.value)},_vm.$v.ruta.telefono_contacto.$touch]}}),(
                          (!_vm.$v.ruta.telefono_contacto.$dirty ||
                            _vm.$v.ruta.telefono_contacto.$invalid) &&
                            (_vm.ruta.telefono_contacto == '' ||
                              _vm.ruta.telefono_contacto == null)
                        )?_c('div',[_vm._m(6)]):_vm._e(),(
                          _vm.$v.ruta.telefono_contacto.$invalid &&
                            _vm.ruta.telefono_contacto != '' &&
                            _vm.ruta.telefono_contacto != null
                        )?_c('div',[_vm._m(7)]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ruta.email_contacto),expression:"ruta.email_contacto"}],staticClass:"form-control form-control-sm",class:_vm.$v.ruta.email_contacto.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","id":"email_contacto"},domProps:{"value":(_vm.ruta.email_contacto)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.ruta, "email_contacto", $event.target.value)},_vm.$v.ruta.email_contacto.$touch]}}),(
                          (!_vm.$v.ruta.email_contacto.$dirty ||
                            _vm.$v.ruta.email_contacto.$invalid) &&
                            (_vm.ruta.email_contacto == '' ||
                              _vm.ruta.email_contacto == null)
                        )?_c('div',[_vm._m(8)]):_vm._e(),(
                          !_vm.isValidEmail &&
                            _vm.ruta.email_contacto != '' &&
                            _vm.ruta.email_contacto != null
                        )?_c('div',[_vm._m(9)]):_vm._e()])]):_vm._e()],2)])]),_c('div',{staticClass:"col-md-12 text-center"},[(!(_vm.ruta_descargue == 1 && _vm.ruta_cargue == 1))?_c('h6',[_vm._m(10)]):_vm._e()])]),(_vm.rutas.length > 0)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header card text-bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',{staticClass:"mb-0"},[_c('span',{staticClass:"badge badge-info"},[_vm._v(" Detalle Carga")]),_c('div',{staticClass:"btn-group float-right"},[_c('button',{staticClass:"btn btn-link btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.plantillaCarga(
                            _vm.tipoServSolicitud.material_inventarios
                          )}}},[_vm._v(" Descargar Plantilla "),_c('i',{staticClass:"fas fa-download"})]),_c('button',{staticClass:"btn btn-sm bg-navy",staticStyle:{"cursor":"pointer"},attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-import-carga"},on:{"click":function($event){return _vm.llenarModal(_vm.tipoServSolicitud.material_inventarios)}}},[_c('i',{staticClass:"fas fa-file-import"})])])])])])]),_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm text-xs"},[_c('thead',{staticClass:"bg-gray text-center"},[_c('tr',[_c('th',{staticClass:"text-nowrap"},[_vm._v(" Acciones "),_c('button',{staticClass:"btn btn-sm bg-primary text-white",staticStyle:{"cursor":"pointer"},attrs:{"type":"button","disabled":_vm.estado_det_carga == 1 ||
                            _vm.$parent.form.estado == 2 ||
                            _vm.$parent.form.estado == 3 ||
                            _vm.$parent.form.estado == 4},on:{"click":function($event){return _vm.addDetCarga()}}},[_c('i',{staticClass:"fas fa-plus"})])]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.tipoServSolicitud.material_inventarios == 1),expression:"tipoServSolicitud.material_inventarios == 1"}]},[_vm._v(" # Pedido Compra de Material ")]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.tipoServSolicitud.material_inventarios == 1),expression:"tipoServSolicitud.material_inventarios == 1"}]},[_vm._v(" Pos. en el Pedido ")]),_c('th',{staticClass:"text-nowrap"},[_vm._v(" Descripción del material o equipos ")]),_c('th',{staticStyle:{"min-width":"8em"}},[_vm._v("Largo x Ancho x Alto (MTS)")]),_c('th',[_vm._v("Peso(Kg)")]),_c('th',[_vm._v("Valor del Material")]),_c('th',[_vm._v("Moneda Área")])])]),_c('tbody',{attrs:{"id":"tbody"}},[_vm._l((_vm.detCargas),function(detCarg,index){return _c('tr',{key:detCarg.id},[_c('td',{staticClass:"text-center"},[(_vm.estado_det_carga != 2)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":_vm.estado_det_carga != -1 ||
                              _vm.$parent.form.estado == 2 ||
                              _vm.$parent.form.estado == 3 ||
                              _vm.$parent.form.estado == 4},on:{"click":function($event){return _vm.editDetCarga(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":_vm.estado_det_carga != -1 ||
                              _vm.$parent.form.estado == 2 ||
                              _vm.$parent.form.estado == 3 ||
                              _vm.$parent.form.estado == 4},on:{"click":function($event){return _vm.destroyDetCarga(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                          _vm.estado_det_carga == 2 && _vm.id_actualizar_det == index
                        )?_c('div',{staticClass:"btn-group"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.detCarga.$invalid),expression:"!$v.detCarga.$invalid"}],staticClass:"btn bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveUpdateDetCarga(index)}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelDetCarga()}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                          _vm.estado_det_carga == 2 && _vm.id_actualizar_det !== index
                        )?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":""},on:{"click":function($event){return _vm.editDetCarga(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":""},on:{"click":function($event){return _vm.destroyDetCarga(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]),(_vm.tipoServSolicitud.material_inventarios == 1)?_c('td',{staticClass:"text-center"},[(
                          _vm.estado_det_carga == 2 && _vm.id_actualizar_det == index
                        )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.no_pedido_compra),expression:"detCarga.no_pedido_compra"}],staticClass:"form-control form-control-sm",class:_vm.$v.detCarga.no_pedido_compra.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"number","id":"cantidad_vehiculos"},domProps:{"value":(_vm.detCarga.no_pedido_compra)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "no_pedido_compra", $event.target.value)}}})]):_c('div',[_vm._v(" "+_vm._s(detCarg.no_pedido_compra)+" ")])]):_vm._e(),(_vm.tipoServSolicitud.material_inventarios == 1)?_c('td',{staticClass:"text-center"},[(
                          _vm.estado_det_carga == 2 && _vm.id_actualizar_det == index
                        )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.pos_pedido),expression:"detCarga.pos_pedido"}],staticClass:"form-control form-control-sm",class:_vm.$v.detCarga.pos_pedido.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","id":"pos_pedido"},domProps:{"value":(_vm.detCarga.pos_pedido)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "pos_pedido", $event.target.value)}}})]):_c('div',[_vm._v(_vm._s(detCarg.pos_pedido))])]):_vm._e(),_c('td',{staticClass:"text-center"},[(
                          _vm.estado_det_carga == 2 && _vm.id_actualizar_det == index
                        )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.descripcion_material),expression:"detCarga.descripcion_material"}],staticClass:"form-control form-control-sm",class:_vm.$v.detCarga.descripcion_material.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","id":"descripcion_material"},domProps:{"value":(_vm.detCarga.descripcion_material)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "descripcion_material", $event.target.value)}}})]):_c('div',[_vm._v(" "+_vm._s(detCarg.descripcion_material)+" ")])]),_c('td',{staticClass:"text-center"},[(
                          _vm.estado_det_carga == 2 && _vm.id_actualizar_det == index
                        )?_c('div',{staticClass:"form-group row ml-1 mr-1 p-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.largo),expression:"detCarga.largo"}],staticClass:"form-control form-control-sm col-md-4 pr-0 pl-0 p-1",class:_vm.$v.detCarga.largo.$invalid
                              ? 'is-invalid'
                              : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number"},domProps:{"value":(_vm.detCarga.largo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "largo", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.ancho),expression:"detCarga.ancho"}],staticClass:"form-control form-control-sm col-md-4 pr-0 pl-0 p-1",class:_vm.$v.detCarga.ancho.$invalid
                              ? 'is-invalid'
                              : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number"},domProps:{"value":(_vm.detCarga.ancho)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "ancho", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.alto),expression:"detCarga.alto"}],staticClass:"form-control form-control-sm col-md-4 pr-0 pl-0 p-1",class:_vm.$v.detCarga.alto.$invalid
                              ? 'is-invalid'
                              : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number"},domProps:{"value":(_vm.detCarga.alto)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "alto", $event.target.value)}}})]):_c('div',[_vm._v(" "+_vm._s(detCarg.largo)+" X "+_vm._s(detCarg.ancho)+" X "+_vm._s(detCarg.alto)+" ")])]),_c('td',{staticClass:"text-center"},[(
                          _vm.estado_det_carga == 2 && _vm.id_actualizar_det == index
                        )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.peso),expression:"detCarga.peso"}],staticClass:"form-control form-control-sm",class:_vm.$v.detCarga.peso.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"number","id":"peso"},domProps:{"value":(_vm.detCarga.peso)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "peso", $event.target.value)}}})]):_c('div',[_vm._v(_vm._s(detCarg.peso))])]),_c('td',{staticClass:"text-center"},[(
                          _vm.estado_det_carga == 2 && _vm.id_actualizar_det == index
                        )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.valor),expression:"detCarga.valor"}],staticClass:"form-control form-control-sm",class:_vm.$v.detCarga.valor.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"number","id":"valor"},domProps:{"value":(_vm.detCarga.valor)},on:{"change":function($event){return _vm.validarMaterial()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "valor", $event.target.value)}}})]):_c('div',[_vm._v(_vm._s(detCarg.valor))])]),_c('td',{staticClass:"text-center"},[(
                          _vm.estado_det_carga == 2 && _vm.id_actualizar_det == index
                        )?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.moneda_obj),expression:"detCarga.moneda_obj"}],staticClass:"form-control form-control-sm",class:_vm.$v.detCarga.moneda_obj.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"id":"moneda"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.detCarga, "moneda_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.monedas),function(moneda){return _c('option',{key:moneda.numeracion,domProps:{"value":moneda}},[_vm._v(" "+_vm._s(moneda.descripcion)+" ")])})],2)]):_c('div',[(
                            detCarg.moneda_obj !== null &&
                              detCarg.moneda_obj !== undefined
                          )?_c('div',[_vm._v(" "+_vm._s(detCarg.moneda_obj.descripcion)+" ")]):_c('div',[_vm._v(" "+_vm._s(detCarg.tipoMoneda)+" ")])])])])}),(_vm.estado_det_carga == 1)?_c('tr',[_c('td',[_c('div',{staticClass:"btn-group float-right"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.detCarga.$invalid),expression:"!$v.detCarga.$invalid"}],staticClass:"btn bg-success",on:{"click":function($event){return _vm.saveDetCarga()}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelDetCarga()}}},[_c('i',{staticClass:"fas fa-trash"})])])]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.tipoServSolicitud.material_inventarios == 1),expression:"tipoServSolicitud.material_inventarios == 1"}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.no_pedido_compra),expression:"detCarga.no_pedido_compra"}],staticClass:"form-control form-control-sm p-2",class:_vm.$v.detCarga.no_pedido_compra.$invalid
                            ? 'is-invalid'
                            : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number","id":"no_pedido_compra"},domProps:{"value":(_vm.detCarga.no_pedido_compra)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "no_pedido_compra", $event.target.value)},_vm.$v.detCarga.no_pedido_compra.$touch]}}),(
                          (!_vm.$v.detCarga.no_pedido_compra.$dirty ||
                            _vm.$v.detCarga.no_pedido_compra.$invalid) &&
                            (_vm.detCarga.no_pedido_compra == '' ||
                              _vm.detCarga.no_pedido_compra == null)
                        )?_c('div',[_vm._m(11)]):_vm._e()]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.tipoServSolicitud.material_inventarios == 1),expression:"tipoServSolicitud.material_inventarios == 1"}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.pos_pedido),expression:"detCarga.pos_pedido"}],staticClass:"form-control form-control-sm p-2",class:_vm.$v.detCarga.pos_pedido.$invalid
                            ? 'is-invalid'
                            : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number","id":"pos_pedido"},domProps:{"value":(_vm.detCarga.pos_pedido)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "pos_pedido", $event.target.value)},_vm.$v.detCarga.pos_pedido.$touch]}}),(
                          (!_vm.$v.detCarga.pos_pedido.$dirty ||
                            _vm.$v.detCarga.pos_pedido.$invalid) &&
                            (_vm.detCarga.pos_pedido == '' ||
                              _vm.detCarga.pos_pedido == null)
                        )?_c('div',[_vm._m(12)]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.descripcion_material),expression:"detCarga.descripcion_material"}],staticClass:"form-control form-control-sm p-2",class:_vm.$v.detCarga.descripcion_material.$invalid
                            ? 'is-invalid'
                            : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"text","id":"descripcion_material"},domProps:{"value":(_vm.detCarga.descripcion_material)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "descripcion_material", $event.target.value)},_vm.$v.detCarga.descripcion_material.$touch]}}),(
                          (!_vm.$v.detCarga.descripcion_material.$dirty ||
                            _vm.$v.detCarga.descripcion_material.$invalid) &&
                            (_vm.detCarga.descripcion_material == '' ||
                              _vm.detCarga.descripcion_material == null)
                        )?_c('div',[_vm._m(13)]):_vm._e()]),_c('td',[_c('div',{staticClass:"form-group row ml-1 mr-1 p-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.largo),expression:"detCarga.largo"}],staticClass:"form-control form-control-sm col-md-4 pr-0 pl-0 p-1",class:_vm.$v.detCarga.largo.$invalid
                              ? 'is-invalid'
                              : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number"},domProps:{"value":(_vm.detCarga.largo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "largo", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.ancho),expression:"detCarga.ancho"}],staticClass:"form-control form-control-sm col-md-4 pr-0 pl-0 p-1",class:_vm.$v.detCarga.ancho.$invalid
                              ? 'is-invalid'
                              : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number"},domProps:{"value":(_vm.detCarga.ancho)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "ancho", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.alto),expression:"detCarga.alto"}],staticClass:"form-control form-control-sm col-md-4 pr-0 pl-0 p-1",class:_vm.$v.detCarga.alto.$invalid
                              ? 'is-invalid'
                              : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number"},domProps:{"value":(_vm.detCarga.alto)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "alto", $event.target.value)}}})])]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.peso),expression:"detCarga.peso"}],staticClass:"form-control form-control-sm p-2",class:_vm.$v.detCarga.peso.$invalid
                            ? 'is-invalid'
                            : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number","id":"peso"},domProps:{"value":(_vm.detCarga.peso)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "peso", $event.target.value)},_vm.$v.detCarga.peso.$touch]}}),(
                          (!_vm.$v.detCarga.peso.$dirty ||
                            _vm.$v.detCarga.peso.$invalid) &&
                            (_vm.detCarga.peso == '' || _vm.detCarga.peso == null)
                        )?_c('div',[_vm._m(14)]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.valor),expression:"detCarga.valor"}],staticClass:"form-control form-control-sm p-2",class:_vm.$v.detCarga.valor.$invalid
                            ? 'is-invalid'
                            : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"type":"number","id":"valor"},domProps:{"value":(_vm.detCarga.valor)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.detCarga, "valor", $event.target.value)},_vm.$v.detCarga.valor.$touch]}}),(
                          (!_vm.$v.detCarga.valor.$dirty ||
                            _vm.$v.detCarga.valor.$invalid) &&
                            (_vm.detCarga.valor == '' || _vm.detCarga.valor == null)
                        )?_c('div',[_vm._m(15)]):_vm._e()]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.detCarga.moneda_obj),expression:"detCarga.moneda_obj"}],staticClass:"form-control form-control-sm col-md-12",class:_vm.$v.detCarga.moneda_obj.$invalid
                            ? 'is-invalid'
                            : 'is-valid',staticStyle:{"font-size":"10px"},attrs:{"id":"moneda"},on:{"input":_vm.$v.detCarga.moneda_obj.$touch,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.detCarga, "moneda_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.monedas),function(moneda){return _c('option',{key:moneda.numeracion,domProps:{"value":moneda}},[_vm._v(" "+_vm._s(moneda.descripcion)+" ")])})],2),(
                          (!_vm.$v.detCarga.moneda_obj.$dirty ||
                            _vm.$v.detCarga.moneda_obj.$invalid) &&
                            (_vm.detCarga.moneda_obj == '' ||
                              _vm.detCarga.moneda_obj == null)
                        )?_c('div',[_vm._m(16)]):_vm._e()])]):_vm._e()],2)])])]):_vm._e()]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('div',[_c('button',{staticClass:"btn bg-frontera text-white",attrs:{"type":"button"},on:{"click":function($event){return _vm.validarSolcitud()}}},[_vm._v(" Guardar ")])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header card text-bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',{staticClass:"mb-0"},[_c('span',{staticClass:"badge badge-info"},[_vm._v(" Rutas")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text"},[_c('span',{staticClass:"fas fa-envelope"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v(" *Teléfono no valido ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v(" *Correo no valido ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"badge badge-warning"},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" Recuerde que se debe agregar sitio de origen y destino")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-danger"},[_c('small',[_vm._v("*Campo requerido")])])
}]

export { render, staticRenderFns }