<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-tipo-servicio"
      style="overflow: auto;"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ accion }} Tipo Servicio</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!----------------------- Tipo Servicio ---------------------->
            <div class="row">
              <!-- Tipo Servicio -->
              <div class="form-group col-md-2">
                <label for="">Tipo Servicio</label>
                <select
                  id="tipo_servicio"
                  class="form-control form-control-sm"
                  v-model="tipoServSolicitud.tipo_servicio"
                  @input="changeTipoVehi()"
                  :class="
                    $v.tipoServSolicitud.tipo_servicio.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_servicio in listasForms.tipos_servicios"
                    :key="tipo_servicio.numeracion"
                    :value="tipo_servicio.numeracion"
                  >
                    {{ tipo_servicio.descripcion }}
                  </option>
                </select>
              </div>
              <!-- Tipo Vh / Equipo / Complementos -->
              <div
                class="form-group col-md-2"
                v-if="tipoServSolicitud.tipo_servicio"
              >
                <div v-if="tipoServSolicitud.tipo_servicio == 1">
                  <label for="">Tipo Vehículos</label>
                  <select
                    id="tipo_vh_equi"
                    class="form-control form-control-sm"
                    v-model="tipoServSolicitud.tipo_vh_equi_obj"
                    :class="
                      $v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="(tipo_vehiculo,
                      index) in listasForms.tipos_vehiculos"
                      :key="index"
                      :value="tipo_vehiculo"
                    >
                      {{ tipo_vehiculo.nombre }}
                    </option>
                  </select>
                </div>
                <div v-else-if="tipoServSolicitud.tipo_servicio == 2">
                  <label for="">Tipo Equipos</label>
                  <select
                    id="tipo_vh_equi"
                    class="form-control form-control-sm"
                    v-model="tipoServSolicitud.tipo_vh_equi_obj"
                    :class="
                      $v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_equipo in listasForms.tipos_equipos"
                      :key="tipo_equipo.numeracion"
                      :value="tipo_equipo"
                    >
                      {{ tipo_equipo.descripcion }}
                    </option>
                  </select>
                </div>
                <div v-else-if="tipoServSolicitud.tipo_servicio == 3">
                  <label for="">Complementarios</label>
                  <select
                    id="tipo_vh_equi"
                    class="form-control form-control-sm"
                    v-model="tipoServSolicitud.tipo_vh_equi_obj"
                    :class="
                      $v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_comple in listasForms.tipos_complementos"
                      :key="tipo_comple.numeracion"
                      :value="tipo_comple"
                    >
                      {{ tipo_comple.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Día / Viaje -->
              <div class="form-group col-md-2">
                <label for="">Día / Viaje</label>
                <select
                  id="tipo_cantidad"
                  class="form-control form-control-sm"
                  v-model="tipoServSolicitud.tipo_cantidad"
                  v-on:input="$v.tipoServSolicitud.tipo_cantidad.$touch"
                  :class="
                    $v.tipoServSolicitud.tipo_cantidad.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option value="1">Día</option>
                  <option value="2">Viaje</option>
                </select>
              </div>
              <!-- Cant Vehículos -->
              <div class="form-group col-md-2">
                <label for="">Cant Vehículos Solicitar</label>
                <input
                  id="cantidad_vehiculos"
                  type="number"
                  class="form-control form-control-sm p-2"
                  v-model="tipoServSolicitud.cantidad_vehiculos"
                  v-on:input="$v.tipoServSolicitud.cantidad_vehiculos.$touch"
                  :class="
                    $v.tipoServSolicitud.cantidad_vehiculos.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <!-- Cant / Tiempo -->
              <div class="form-group col-md-2">
                <label for="" v-if="tipoServSolicitud.tipo_cantidad == 1"
                  >Días <small> (Tiempo) </small></label
                >
                <label for="" v-else>Viajes <small> (Cant) </small></label>
                <input
                  id="cantidad_vehiculos"
                  type="number"
                  class="form-control form-control-sm p-2"
                  v-model="tipoServSolicitud.cantidad"
                  v-on:input="$v.tipoServSolicitud.cantidad.$touch"
                  :class="
                    $v.tipoServSolicitud.cantidad.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <!-- Material Inventarios -->
              <div class="form-group col-md-2">
                <label for="tipo_solicitud">Transporta Material</label
                ><small style="font-size: 0.7em"> (Inventario)</small>
                <select
                  id="material_inventarios"
                  class="form-control form-control-sm"
                  v-model="tipoServSolicitud.material_inventarios"
                  :class="
                    $v.tipoServSolicitud.material_inventarios.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option value="1">Si</option>
                  <option value="2">No</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Observación</label>
                <textarea
                  class="form-control form-control-sm"
                  v-model="tipoServSolicitud.observaciones"
                  :class="
                    $v.tipoServSolicitud.observaciones.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
            </div>
            <!----------------------- Card Ruta --------------------------->
            <div class="card">
              <div class="card-header card text-bold">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="mb-0">
                      <span class="badge badge-info"> Rutas</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <table
                  class="table table-bordered table-striped table-hover table-sm text-xs"
                >
                  <thead class="bg-gray text-center">
                    <tr>
                      <th class="text-nowrap">
                        Acciones
                        <button
                          type="button"
                          class="btn btn-sm bg-primary text-white"
                          @click="addRuta()"
                          :disabled="
                            estado_ruta == 1 ||
                              !$parent.form.id ||
                              $parent.form.estado == 2 ||
                              $parent.form.estado == 3 ||
                              $parent.form.estado == 4
                          "
                          style="cursor: pointer"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </th>
                      <th class="text-center">Tipo Sitio</th>
                      <th class="text-center">Sitio</th>
                      <th class="text-center">Direccion</th>
                      <th class="text-center">Nombre de Contacto</th>
                      <th class="text-center">Número de Contacto</th>
                      <th class="text-center">Correo</th>
                    </tr>
                  </thead>
                  <tbody id="tbody">
                    <tr v-for="(rut, index) in rutas" :key="rut.id">
                      <td class="text-center">
                        <div class="btn-group" v-if="estado_ruta != 2">
                          <button
                            type="button"
                            class="btn bg-navy"
                            :disabled="
                              estado_ruta == 1 ||
                                !$parent.form.id ||
                                $parent.form.estado == 2 ||
                                $parent.form.estado == 3 ||
                                $parent.form.estado == 4
                            "
                            @click="editRuta(index)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn bg-danger"
                            :disabled="
                              estado_ruta == 1 ||
                                !$parent.form.id ||
                                $parent.form.estado == 2 ||
                                $parent.form.estado == 3 ||
                                $parent.form.estado == 4
                            "
                            @click="destroyRuta(index)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                        <div
                          class="btn-group"
                          v-if="estado_ruta == 2 && id_actualizar_ruta == index"
                        >
                          <button
                            type="button"
                            class="btn bg-success"
                            v-show="!$v.ruta.$invalid"
                            @click="saveUpdateRuta(index)"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                          <button class="btn bg-danger" @click="cancelRuta()">
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                        <div
                          class="btn-group"
                          v-if="
                            estado_ruta == 2 && id_actualizar_ruta !== index
                          "
                        >
                          <button
                            type="button"
                            class="btn bg-navy"
                            disabled
                            @click="editRuta(index)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn bg-danger"
                            disabled
                            @click="destroyRuta(index)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            id_actualizar_ruta === index && estado_ruta === 2
                          "
                        >
                          <select
                            placeholder="Tipo sitio"
                            label="nombres"
                            class="form-control form-control-sm p-0"
                            v-model="ruta.tipo_sitio"
                            :class="
                              $v.ruta.tipo_sitio.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option value="1">Origen</option>
                            <option value="2">Destino</option>
                          </select>
                        </div>
                        <div v-else>
                          <span
                            class="badge"
                            :class="
                              rut.tipo_sitio == 1 ? 'bg-success' : 'bg-primary'
                            "
                          >
                            {{ rut.tipo_sitio == 1 ? "Origen" : "Destino" }}
                          </span>
                        </div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            id_actualizar_ruta === index && estado_ruta === 2
                          "
                        >
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="nombre"
                            v-model="ruta.nombre"
                            :class="
                              $v.ruta.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>{{ rut.nombre }}</div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            id_actualizar_ruta === index && estado_ruta === 2
                          "
                        >
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="direccion"
                            v-model="ruta.direccion"
                            :class="
                              $v.ruta.direccion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>{{ rut.direccion }}</div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            id_actualizar_ruta === index && estado_ruta === 2
                          "
                        >
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="nombre_contacto"
                            v-model="ruta.nombre_contacto"
                            :class="
                              $v.ruta.nombre_contacto.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>{{ rut.nombre_contacto }}</div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            id_actualizar_ruta === index && estado_ruta === 2
                          "
                        >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="telefono_contacto"
                            v-model="ruta.telefono_contacto"
                            :class="
                              $v.ruta.telefono_contacto.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>{{ rut.telefono_contacto }}</div>
                      </td>
                      <td class="text-center">
                        <div
                          class="input-group mb-3"
                          v-if="
                            id_actualizar_ruta === index && estado_ruta === 2
                          "
                        >
                          <input
                            type="email"
                            class="form-control form-control-sm"
                            v-model="ruta.email_contacto"
                            id="email_contacto"
                            :class="
                              $v.ruta.email_contacto.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            placeholder="Email"
                          />
                          <div class="input-group-append">
                            <div class="input-group-text">
                              <span class="fas fa-envelope"></span>
                            </div>
                          </div>
                        </div>

                        <div v-else>{{ rut.email_contacto }}</div>
                      </td>
                    </tr>
                    <tr v-if="estado_ruta === 1">
                      <td>
                        <div class="btn-group float-right">
                          <button
                            class="btn bg-success"
                            v-show="!$v.ruta.$invalid"
                            @click="saveRuta()"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                          <button class="btn bg-danger" @click="cancelRuta()">
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                      <td>
                        <select
                          placeholder="Tipo sitio"
                          label="nombres"
                          class="form-control form-control-sm p-0"
                          v-model="ruta.tipo_sitio"
                          v-on:input="$v.ruta.tipo_sitio.$touch"
                          :class="
                            $v.ruta.tipo_sitio.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option value="1">Origen</option>
                          <option value="2">Destino</option>
                        </select>
                        <div
                          v-if="
                            (!$v.ruta.tipo_sitio.$dirty ||
                              $v.ruta.tipo_sitio.$invalid) &&
                              (ruta.tipo_sitio == '' || ruta.tipo_sitio == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="nombre"
                          v-model="ruta.nombre"
                          v-on:input="$v.ruta.nombre.$touch"
                          :class="
                            $v.ruta.nombre.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        />
                        <div
                          v-if="
                            (!$v.ruta.nombre.$dirty ||
                              $v.ruta.nombre.$invalid) &&
                              (ruta.nombre == '' || ruta.nombre == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="direccion"
                          v-model="ruta.direccion"
                          v-on:input="$v.ruta.direccion.$touch"
                          :class="
                            $v.ruta.direccion.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <div
                          v-if="
                            (!$v.ruta.direccion.$dirty ||
                              $v.ruta.direccion.$invalid) &&
                              (ruta.direccion == '' || ruta.direccion == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="nombre_contacto"
                          v-model="ruta.nombre_contacto"
                          v-on:input="$v.ruta.nombre_contacto.$touch"
                          :class="
                            $v.ruta.nombre_contacto.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <div
                          v-if="
                            (!$v.ruta.nombre_contacto.$dirty ||
                              $v.ruta.nombre_contacto.$invalid) &&
                              (ruta.nombre_contacto == '' ||
                                ruta.nombre_contacto == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          id="telefono_contacto"
                          v-model="ruta.telefono_contacto"
                          v-on:input="$v.ruta.telefono_contacto.$touch"
                          :class="
                            $v.ruta.telefono_contacto.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <div
                          v-if="
                            (!$v.ruta.telefono_contacto.$dirty ||
                              $v.ruta.telefono_contacto.$invalid) &&
                              (ruta.telefono_contacto == '' ||
                                ruta.telefono_contacto == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                        <div
                          v-if="
                            $v.ruta.telefono_contacto.$invalid &&
                              ruta.telefono_contacto != '' &&
                              ruta.telefono_contacto != null
                          "
                        >
                          <span class="text-danger">
                            <small> *Teléfono no valido </small>
                          </span>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="email_contacto"
                          v-model="ruta.email_contacto"
                          v-on:input="$v.ruta.email_contacto.$touch"
                          :class="
                            $v.ruta.email_contacto.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                        <div
                          v-if="
                            (!$v.ruta.email_contacto.$dirty ||
                              $v.ruta.email_contacto.$invalid) &&
                              (ruta.email_contacto == '' ||
                                ruta.email_contacto == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                        <div
                          v-if="
                            !isValidEmail &&
                              ruta.email_contacto != '' &&
                              ruta.email_contacto != null
                          "
                        >
                          <span class="text-danger">
                            <small> *Correo no valido </small>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12 text-center">
                <h6 v-if="!(ruta_descargue == 1 && ruta_cargue == 1)">
                  <span class="badge badge-warning"
                    ><i class="fas fa-times-circle"></i> Recuerde que se debe
                    agregar sitio de origen y destino</span
                  >
                </h6>
              </div>
            </div>
            <!---------------------- Card Detalle Carga ------------------>
            <div class="card" v-if="rutas.length > 0">
              <div class="card-header card text-bold">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="mb-0">
                      <span class="badge badge-info"> Detalle Carga</span>
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-link btn-sm"
                          @click="
                            plantillaCarga(
                              tipoServSolicitud.material_inventarios
                            )
                          "
                        >
                          Descargar Plantilla
                          <i class="fas fa-download"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-sm bg-navy"
                          data-toggle="modal"
                          data-target="#modal-form-import-carga"
                          style="cursor: pointer"
                          @click="
                            llenarModal(tipoServSolicitud.material_inventarios)
                          "
                        >
                          <i class="fas fa-file-import"></i>
                        </button>
                      </div>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <table
                  class="table table-bordered table-striped table-hover table-sm text-xs"
                >
                  <thead class="bg-gray text-center">
                    <tr>
                      <th class="text-nowrap">
                        Acciones
                        <button
                          type="button"
                          class="btn btn-sm bg-primary text-white"
                          @click="addDetCarga()"
                          :disabled="
                            estado_det_carga == 1 ||
                              $parent.form.estado == 2 ||
                              $parent.form.estado == 3 ||
                              $parent.form.estado == 4
                          "
                          style="cursor: pointer"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </th>
                      <th v-show="tipoServSolicitud.material_inventarios == 1">
                        # Pedido Compra de Material
                      </th>
                      <th v-show="tipoServSolicitud.material_inventarios == 1">
                        Pos. en el Pedido
                      </th>
                      <th class="text-nowrap">
                        Descripción del material o equipos
                      </th>
                      <th style="min-width: 8em">Largo x Ancho x Alto (MTS)</th>
                      <th>Peso(Kg)</th>
                      <th>Valor del Material</th>
                      <th>Moneda Área</th>
                    </tr>
                  </thead>
                  <tbody id="tbody">
                    <tr v-for="(detCarg, index) in detCargas" :key="detCarg.id">
                      <td class="text-center">
                        <div class="btn-group" v-if="estado_det_carga != 2">
                          <button
                            type="button"
                            class="btn bg-navy"
                            :disabled="
                              estado_det_carga != -1 ||
                                $parent.form.estado == 2 ||
                                $parent.form.estado == 3 ||
                                $parent.form.estado == 4
                            "
                            @click="editDetCarga(index)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn bg-danger"
                            :disabled="
                              estado_det_carga != -1 ||
                                $parent.form.estado == 2 ||
                                $parent.form.estado == 3 ||
                                $parent.form.estado == 4
                            "
                            @click="destroyDetCarga(index)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                        <div
                          class="btn-group"
                          v-if="
                            estado_det_carga == 2 && id_actualizar_det == index
                          "
                        >
                          <button
                            type="button"
                            class="btn bg-success"
                            v-show="!$v.detCarga.$invalid"
                            @click="saveUpdateDetCarga(index)"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                          <button
                            class="btn bg-danger"
                            @click="cancelDetCarga()"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                        <div
                          class="btn-group"
                          v-if="
                            estado_det_carga == 2 && id_actualizar_det !== index
                          "
                        >
                          <button
                            type="button"
                            class="btn bg-navy"
                            disabled
                            @click="editDetCarga(index)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn bg-danger"
                            disabled
                            @click="destroyDetCarga(index)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                      <td
                        class="text-center"
                        v-if="tipoServSolicitud.material_inventarios == 1"
                      >
                        <div
                          v-if="
                            estado_det_carga == 2 && id_actualizar_det == index
                          "
                        >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="cantidad_vehiculos"
                            v-model="detCarga.no_pedido_compra"
                            :class="
                              $v.detCarga.no_pedido_compra.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>
                          {{ detCarg.no_pedido_compra }}
                        </div>
                      </td>
                      <td
                        class="text-center"
                        v-if="tipoServSolicitud.material_inventarios == 1"
                      >
                        <div
                          v-if="
                            estado_det_carga == 2 && id_actualizar_det == index
                          "
                        >
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="pos_pedido"
                            v-model="detCarga.pos_pedido"
                            :class="
                              $v.detCarga.pos_pedido.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>{{ detCarg.pos_pedido }}</div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            estado_det_carga == 2 && id_actualizar_det == index
                          "
                        >
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="descripcion_material"
                            v-model="detCarga.descripcion_material"
                            :class="
                              $v.detCarga.descripcion_material.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>
                          {{ detCarg.descripcion_material }}
                        </div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            estado_det_carga == 2 && id_actualizar_det == index
                          "
                          class="form-group row ml-1 mr-1 p-0"
                        >
                          <input
                            type="number"
                            style="font-size: 10px"
                            v-model="detCarga.largo"
                            class="form-control form-control-sm col-md-4 pr-0 pl-0 p-1"
                            :class="
                              $v.detCarga.largo.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                          <input
                            type="number"
                            v-model="detCarga.ancho"
                            style="font-size: 10px"
                            class="form-control form-control-sm col-md-4 pr-0 pl-0 p-1"
                            :class="
                              $v.detCarga.ancho.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                          <input
                            type="number"
                            v-model="detCarga.alto"
                            style="font-size: 10px"
                            class="form-control form-control-sm col-md-4 pr-0 pl-0 p-1"
                            :class="
                              $v.detCarga.alto.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>
                          {{ detCarg.largo }} X {{ detCarg.ancho }} X
                          {{ detCarg.alto }}
                        </div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            estado_det_carga == 2 && id_actualizar_det == index
                          "
                        >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="peso"
                            v-model="detCarga.peso"
                            :class="
                              $v.detCarga.peso.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>{{ detCarg.peso }}</div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            estado_det_carga == 2 && id_actualizar_det == index
                          "
                        >
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="valor"
                            v-model="detCarga.valor"
                            @change="validarMaterial()"
                            :class="
                              $v.detCarga.valor.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div v-else>{{ detCarg.valor }}</div>
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            estado_det_carga == 2 && id_actualizar_det == index
                          "
                        >
                          <select
                            id="moneda"
                            class="form-control form-control-sm"
                            v-model="detCarga.moneda_obj"
                            :class="
                              $v.detCarga.moneda_obj.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="moneda in listasForms.monedas"
                              :key="moneda.numeracion"
                              :value="moneda"
                            >
                              {{ moneda.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div v-else>
                          <div
                            v-if="
                              detCarg.moneda_obj !== null &&
                                detCarg.moneda_obj !== undefined
                            "
                          >
                            {{ detCarg.moneda_obj.descripcion }}
                          </div>
                          <div v-else>
                            {{ detCarg.tipoMoneda }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="estado_det_carga == 1">
                      <td>
                        <div class="btn-group float-right">
                          <button
                            class="btn bg-success"
                            v-show="!$v.detCarga.$invalid"
                            @click="saveDetCarga()"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                          <button
                            class="btn bg-danger"
                            @click="cancelDetCarga()"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                      <td v-show="tipoServSolicitud.material_inventarios == 1">
                        <input
                          type="number"
                          class="form-control form-control-sm p-2"
                          id="no_pedido_compra"
                          v-model="detCarga.no_pedido_compra"
                          v-on:input="$v.detCarga.no_pedido_compra.$touch"
                          :class="
                            $v.detCarga.no_pedido_compra.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 10px"
                        />
                        <div
                          v-if="
                            (!$v.detCarga.no_pedido_compra.$dirty ||
                              $v.detCarga.no_pedido_compra.$invalid) &&
                              (detCarga.no_pedido_compra == '' ||
                                detCarga.no_pedido_compra == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                      <td v-show="tipoServSolicitud.material_inventarios == 1">
                        <input
                          type="number"
                          class="form-control form-control-sm p-2"
                          id="pos_pedido"
                          v-model="detCarga.pos_pedido"
                          v-on:input="$v.detCarga.pos_pedido.$touch"
                          :class="
                            $v.detCarga.pos_pedido.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 10px"
                        />
                        <div
                          v-if="
                            (!$v.detCarga.pos_pedido.$dirty ||
                              $v.detCarga.pos_pedido.$invalid) &&
                              (detCarga.pos_pedido == '' ||
                                detCarga.pos_pedido == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control form-control-sm p-2"
                          id="descripcion_material"
                          v-model="detCarga.descripcion_material"
                          v-on:input="$v.detCarga.descripcion_material.$touch"
                          :class="
                            $v.detCarga.descripcion_material.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 10px"
                        />
                        <div
                          v-if="
                            (!$v.detCarga.descripcion_material.$dirty ||
                              $v.detCarga.descripcion_material.$invalid) &&
                              (detCarga.descripcion_material == '' ||
                                detCarga.descripcion_material == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                      <td>
                        <div class="form-group row ml-1 mr-1 p-0">
                          <input
                            type="number"
                            style="font-size: 10px"
                            v-model="detCarga.largo"
                            class="form-control form-control-sm col-md-4 pr-0 pl-0 p-1"
                            :class="
                              $v.detCarga.largo.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                          <input
                            type="number"
                            v-model="detCarga.ancho"
                            style="font-size: 10px"
                            class="form-control form-control-sm col-md-4 pr-0 pl-0 p-1"
                            :class="
                              $v.detCarga.ancho.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                          <input
                            type="number"
                            v-model="detCarga.alto"
                            style="font-size: 10px"
                            class="form-control form-control-sm col-md-4 pr-0 pl-0 p-1"
                            :class="
                              $v.detCarga.alto.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm p-2"
                          id="peso"
                          v-model="detCarga.peso"
                          v-on:input="$v.detCarga.peso.$touch"
                          :class="
                            $v.detCarga.peso.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 10px"
                        />
                        <div
                          v-if="
                            (!$v.detCarga.peso.$dirty ||
                              $v.detCarga.peso.$invalid) &&
                              (detCarga.peso == '' || detCarga.peso == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control form-control-sm p-2"
                          id="valor"
                          v-model="detCarga.valor"
                          v-on:input="$v.detCarga.valor.$touch"
                          :class="
                            $v.detCarga.valor.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 10px"
                        />
                        <div
                          v-if="
                            (!$v.detCarga.valor.$dirty ||
                              $v.detCarga.valor.$invalid) &&
                              (detCarga.valor == '' || detCarga.valor == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                      <td>
                        <select
                          id="moneda"
                          class="form-control form-control-sm col-md-12"
                          v-model="detCarga.moneda_obj"
                          v-on:input="$v.detCarga.moneda_obj.$touch"
                          :class="
                            $v.detCarga.moneda_obj.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          style="font-size: 10px"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="moneda in listasForms.monedas"
                            :key="moneda.numeracion"
                            :value="moneda"
                          >
                            {{ moneda.descripcion }}
                          </option>
                        </select>
                        <div
                          v-if="
                            (!$v.detCarga.moneda_obj.$dirty ||
                              $v.detCarga.moneda_obj.$invalid) &&
                              (detCarga.moneda_obj == '' ||
                                detCarga.moneda_obj == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn bg-frontera text-white"
                @click="validarSolcitud()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import {
  required,
  email,
  minLength,
  maxLength,
  minValue,
} from "vuelidate/lib/validators";

export default {
  name: "CsSolicitudesNacionalesModal",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      accion: "",
      metodo: null,
      /* Datos Tipo Servicio*/
      tipoServSolicitudes: [],
      tipo_servicio_id: null,
      tipoServSolicitud: {
        id: null,
        cs_solicitud_nacional_id: null,
        tipo_servicio: null,
        tipo_vh_equi: null,
        tipo_vh_equi_obj: null,
        material_inventarios: null,
        cantidad: null,
        cantidad_vehiculos: null,
        tipo_cantidad: null,
        observaciones: null,
        estado: 3,
      },
      estado_det_solicitud: -1,
      id_actualizar: -1,
      detSolicitudAnt: null,
      /* Datos Rutas */
      rutas: [],
      ruta: {
        id: null,
        cs_solicitud_nacional_id: null,
        tipo_sitio: null,
        nombre: null,
        direccion: null,
        nombre_contacto: null,
        telefono_contacto: null,
        email_contacto: null,
      },
      estado_ruta: -1,
      ruta_cargue: 0,
      ruta_descargue: 0,
      id_actualizar_ruta: -1,
      /* Datos Detalle Carga */
      detCargas: [],
      detCarga: {
        id: null,
        cs_solicitud_nacional_id: null,
        no_pedido_compra_: null,
        pos_pedido: null,
        descripcion_material: null,
        peso: null,
        valor: null,
        moneda: null,
        moneda_obj: null,
      },
      estado_det_carga: -1,
      id_actualizar_det: -1,
      detCargaAnt: null,
      /* ---------------------- */
      listasForms: {
        tipos_servicios: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
        tipos_complementos: [],
        monedas: [],
      },
    };
  },
  computed: {
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.ruta.email_contacto);
    },
  },

  validations() {
    if (this.tipoServSolicitud.material_inventarios == 1) {
      return {
        /* Validación Tipo Servicio */
        tipoServSolicitud: {
          tipo_servicio: {
            required,
          },
          tipo_vh_equi_obj: {
            required,
          },
          material_inventarios: {
            required,
          },
          cantidad: {
            required,
            minValue: minValue(1),
          },
          cantidad_vehiculos: {
            required,
            minValue: minValue(1),
          },
          tipo_cantidad: {
            required,
          },
          observaciones: {
            required,
          },
        },
        /* Validación Ruta */
        ruta: {
          tipo_sitio: {
            required,
          },
          nombre: {
            required,
          },
          direccion: {
            required,
          },
          nombre_contacto: {
            required,
          },
          telefono_contacto: {
            required,
            maxLength: maxLength(10),
            minLength: minLength(10),
            minValue: minValue(1),
          },
          email_contacto: {
            required,
            email,
          },
        },
        /* Validación Detalle Cargue */
        detCarga: {
          no_pedido_compra: {
            required,
          },
          pos_pedido: {
            required,
          },
          descripcion_material: {
            required,
          },
          largo: {
            required,
            minValue: minValue(0),
          },
          alto: {
            required,
            minValue: minValue(0),
          },
          ancho: {
            required,
            minValue: minValue(0),
          },
          peso: {
            required,
            minValue: minValue(0),
          },
          valor: {
            required,
            minValue: minValue(0),
          },
          moneda_obj: {
            required,
          },
        },
      };
    } else {
      return {
        /* Validación Tipo Servicio */
        tipoServSolicitud: {
          tipo_servicio: {
            required,
          },
          tipo_vh_equi_obj: {
            required,
          },
          material_inventarios: {
            required,
          },
          cantidad: {
            required,
            minValue: minValue(1),
          },
          cantidad_vehiculos: {
            required,
            minValue: minValue(1),
          },
          tipo_cantidad: {
            required,
          },
          observaciones: {
            required,
          },
        },
        /* Validación Ruta */
        ruta: {
          tipo_sitio: {
            required,
          },
          nombre: {
            required,
          },
          direccion: {
            required,
          },
          nombre_contacto: {
            required,
          },
          telefono_contacto: {
            required,
            maxLength: maxLength(10),
            minLength: minLength(10),
            minValue: minValue(1),
          },
          email_contacto: {
            required,
            email,
          },
        },
        /* Validación Detalle Cargue */
        detCarga: {
          no_pedido_compra: {},
          pos_pedido: {},
          descripcion_material: {
            required,
          },
          largo: {
            required,
            minValue: minValue(0),
          },
          alto: {
            required,
            minValue: minValue(0),
          },
          ancho: {
            required,
            minValue: minValue(0),
          },
          peso: {
            required,
            minValue: minValue(0),
          },
          valor: {
            required,
            minValue: minValue(0),
          },
          moneda_obj: {
            required,
          },
        },
      };
    }
  },

  methods: {
    async init(accion, tipoServicio) {
      this.accion = accion;
      await this.listas();
      if (this.accion == "Editar") {
        this.metodo = "PUT";
        this.tipo_servicio_id = tipoServicio;
        await this.getRutas();
        await this.getTipoServSolicitudes();
        await this.getDetCarga();
      } else {
        this.metodo = "POST";
        await this.limpiarModal();
      }
    },
    /*------------------------------------------------------- */
    /* Datos Tipo Servicio */
    async getTipoServSolicitudes() {
      this.cargando = true;
      await axios
        .get("/api/funcionariosFrontera/solicitudesNacionalesServiciosTipo", {
          params: {
            solicitudNacional_id: this.$parent.form.id,
            tipo_servicio_id: this.tipo_servicio_id,
          },
        })
        .then(async (response) => {
          this.tipoServSolicitud = response.data;

          if (this.tipoServSolicitud.tipo_servicio == 1) {
            if (this.tipoServSolicitud.tipo_remolque !== null) {
              this.tipoServSolicitud.tipo_vh_equi_obj = this.listasForms.tipos_vehiculos.find(
                (item) =>
                  item.tipo_vehi == this.tipoServSolicitud.tipo_vh_equi &&
                  item.id == this.tipoServSolicitud.tipo_remolque &&
                  item.tipo == "tr"
              );
            } else {
              this.tipoServSolicitud.tipo_vh_equi_obj = this.listasForms.tipos_vehiculos.find(
                (item) =>
                  item.tipo_vehi == this.tipoServSolicitud.tipo_vh_equi &&
                  item.tipo == "tv"
              );
            }
          }

          if (this.tipoServSolicitud.tipo_servicio == 2) {
            this.tipoServSolicitud.tipo_vh_equi_obj = this.listasForms.tipos_equipos.find(
              (item) => item.numeracion == this.tipoServSolicitud.tipo_vh_equi
            );
          }

          if (this.tipoServSolicitud.tipo_servicio == 3) {
            this.tipoServSolicitud.tipo_vh_equi_obj = this.listasForms.tipos_complementos.find(
              (item) => item.numeracion == this.tipoServSolicitud.tipo_vh_equi
            );
          }

          

          this.cargando = false;
        });
    },

    async getTipoServicio() {
      await axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    async getTipoVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then(async function(response) {
          let respuesta = response.data;
          //me.listasForms.tipos_vehiculos = respuesta;
          let lista = [];
          for (let i = 0; i < respuesta.length; i++) {
            lista.push({
              id: respuesta[i].id,
              tipo: "tv",
              tipo_vehi: respuesta[i].id,
              nombre: respuesta[i].nombre,
            });
          }

          await axios
            .get("/api/lista/187", {
              params: {
                cod_alternos: [2, 3],
              },
            })
            .then(function(response) {
              let respuestaR = response.data;
              for (let i = 0; i < respuestaR.length; i++) {
                lista.push({
                  id: respuestaR[i].numeracion,
                  tipo: "tr",
                  tipo_vehi: 1,
                  nombre: "CABEZOTE - " + respuestaR[i].descripcion,
                });
              }
            })
            .catch(function(error) {});
          me.listasForms.tipos_vehiculos = lista;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTipoEquipo() {
      let me = this;
      await axios
        .get("/api/lista/129", {
          params: {
            cod_alternos: [2, 3],
          },
        })
        .then(function(response) {
          me.listasForms.tipos_equipos = response.data;
        })
        .catch(function(error) {});
    },

    async getTipoComplementarios() {
      await axios.get("/api/lista/195").then((response) => {
        this.listasForms.tipos_complementos = response.data;
      });
    },

    changeTipoVehi() {
      this.tipoServSolicitud.tipo_vh_equi = null;
    },

    async limpiarTipoServSolicitud() {
      this.tipoServSolicitud = {
        id: null,
        cs_solicitud_nacional_id: null,
        tipo_servicio: null,
        tipo_vh_equi: null,
        material_inventarios: null,
        cantidad: null,
        cantidad_vehiculos: null,
        tipo_cantidad: null,
        observaciones: null,
      };
    },
    /*------------------------------------------------------- */
    /* Datos Rutas */
    async getRutas() {
      this.cargando = true;
      await axios
        .get("/api/funcionariosFrontera/solicitudesNacionalesRutaServicio", {
          params: {
            solicitudNacional_id: this.$parent.form.id,
            tipo_servicio_id: this.tipo_servicio_id,
          },
        })
        .then(async (response) => {
          this.rutas = response.data;
          await this.calcularRutas();
          this.cargando = false;
        });
    },

    async calcularRutas() {
      this.ruta_cargue = 0;
      this.ruta_descargue = 0;
      this.rutas.forEach((element) => {
        if (element.tipo_sitio == 1) {
          this.ruta_cargue++;
        }
        if (element.tipo_sitio == 2) {
          this.ruta_descargue++;
        }
      });
    },

    async addRuta() {
      await this.limpiarRuta();
      this.ruta.cs_solicitud_nacional_id = this.$parent.form.id;
      this.estado_ruta = 1;
    },

    async limpiarRuta() {
      this.ruta = {
        id: null,
        cs_solicitud_nacional_id: null,
        tipo_sitio: null,
        nombre: null,
        direccion: null,
        nombre_contacto: null,
        telefono_contacto: null,
        email_contacto: null,
      };
    },

    async editRuta(index) {
      this.id_actualizar_ruta = index;
      this.estado_ruta = 2;
      this.ruta = { ...this.rutas[index] };
    },

    async cancelRuta() {
      this.estado_ruta = -1;
    },

    async saveRuta() {
      this.rutas.push(this.ruta);
      await this.calcularRutas();
      this.estado_ruta = -1;
    },

    async saveUpdateRuta(index) {
      this.rutas[index] = this.ruta;
      this.estado_ruta = -1;
      this.id_actualizar_ruta;
      await this.calcularRutas();
    },

    async destroyRuta(index) {
      await this.rutas.splice(index, 1);
      await this.calcularRutas();
    },
    /*------------------------------------------------------- */
    /* Detalle Carga */
    async getDetCarga() {
      this.cargando = true;
      await axios
        .get("/api/funcionariosFrontera/solicitudesNacionalesCargaServicio", {
          params: {
            solicitudNacional_id: this.$parent.form.id,
            tipo_servicio_id: this.tipo_servicio_id,
          },
        })
        .then(async (response) => {
          this.detCargas = await response.data;
          await this.llenarDetCarga();
          this.cargando = false;
        });
    },

    async getMoneda() {
      await axios.get("/api/lista/134").then((response) => {
        this.listasForms.monedas = response.data;
      });
    },

    async llenarDetCarga() {
      await this.detCargas.forEach(async (element) => {
        element.moneda_obj = await this.listasForms.monedas.filter((item) => {
          if (item.numeracion == element.moneda) {
            return item;
          }
        })[0];
      });
    },

    async addDetCarga() {
      await this.limpiarDetCarga();
      this.detCarga.cs_solicitud_nacional_id = this.$parent.form.id;
      this.estado_det_carga = 1;
    },

    async cancelDetCarga() {
      this.estado_det_carga = -1;
    },

    async saveDetCarga() {
      let me = this;
      me.detCarga.moneda = me.detCarga.moneda_obj.numeracion;
      me.detCargas.push(me.detCarga);
      me.estado_det_carga = -1;
    },

    async editDetCarga(index) {
      this.id_actualizar_det = index;
      this.estado_det_carga = 2;
      this.detCarga = { ...this.detCargas[index] };
    },

    async saveUpdateDetCarga(index) {
      this.detCarga.moneda = this.detCarga.moneda_obj.numeracion;
      this.detCargas[index] = this.detCarga;
      this.estado_det_carga = -1;
      this.id_actualizar_det = -1;
    },

    async destroyDetCarga(index) {
      this.detCargas.splice(index, 1);
    },

    async limpiarDetCarga() {
      this.detCarga = {
        id: null,
        cs_solicitud_nacional_id: null,
        no_pedido_compra: null,
        pos_pedido: null,
        descripcion_material: null,
        alto: null,
        largo: null,
        ancho: null,
        peso: null,
        valor: null,
        moneda: null,
      };
    },

    plantillaCarga(material_inventarios) {
      this.cargando = true;
      axios({
        method: "get",
        url:
          "/api/funcionariosFrontera/solicitudesNacionalesPlantillaDetCarga/plantilla",
        params: { material_inventarios: material_inventarios },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error inesperado " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    llenarModal(material_inventarios) {
      this.$parent.$refs.CsSolicitudesNacionalesImport.getModal(
        material_inventarios
      );
    },

    async listas() {
      await this.getTipoVehiculos();
      await this.getTipoEquipo();
      await this.getTipoComplementarios();
      await this.getTipoServicio();
      await this.getMoneda();
    },

    async validarSolcitud() {
      if (this.$v.tipoServSolicitud.$invalid) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha ingresado los datos del Tipo de Vehículo. Por favor validar ",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      } else if (this.rutas.length == 0) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha ingresado los datos de la Ruta para esta solicitud. Por favor validar ",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      } else if (this.detCargas.length == 0) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha ingresado los datos del Detalle de la carga. Por favor validar ",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      } else {
        let data = {
          solicitudNacional_id: this.$parent.form.id,
          tipoServSolicitud: this.tipoServSolicitud,
          rutas: this.rutas,
          detCargas: this.detCargas,
        };
        await this.asingTipoVhei();
        await this.saveDetalle(data);
      }
    },

    async saveDetalle(data) {
      this.cargando = true;
      await axios({
        method: this.metodo,
        url: "/api/funcionariosFrontera/solicitudesNacionalesSaveDetalle",
        data: data,
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "El tipo de servicio se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
          this.$refs.closeModal.click();
          this.$parent.getServicio();
        })
        .catch((e) => {
          this.$cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    limpiarModal() {
      this.detCargas = [];
      this.detCarga = {
        id: null,
        cs_solicitud_nacional_id: null,
        no_pedido_compra: null,
        pos_pedido: null,
        descripcion_material: null,
        alto: null,
        ancho: null,
        largo: null,
        peso: null,
        valor: null,
        moneda: null,
      };

      this.rutas = [];
      this.ruta = {
        id: null,
        cs_solicitud_nacional_id: null,
        tipo_sitio: null,
        nombre: null,
        direccion: null,
        nombre_contacto: null,
        telefono_contacto: null,
        email_contacto: null,
      };

      this.tipoServSolicitud = {
        id: null,
        cs_solicitud_nacional_id: null,
        tipo_servicio: null,
        tipo_vh_equi: null,
        material_inventarios: null,
        cantidad: null,
        cantidad_vehiculos: null,
        tipo_cantidad: null,
        observaciones: null,
      };
    },

    validarMaterial() {
      if (this.detCarga.valor == 0) {
        this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "Tenga en cuenta que este será el valor que se reporta a seguros si sugiere alguna novedad.",
          timer: 4000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      }
    },

    async asingTipoVhei() {
      let me = this;
      if (me.tipoServSolicitud.tipo_servicio == 1) {
        if (me.tipoServSolicitud.tipo_vh_equi_obj.tipo == "tv") {
          me.tipoServSolicitud.tipo_vh_equi =
            me.tipoServSolicitud.tipo_vh_equi_obj.id;
          me.tipoServSolicitud.tipo_remolque = null;
        } else if (me.tipoServSolicitud.tipo_vh_equi_obj.tipo == "tr") {
          me.tipoServSolicitud.tipo_vh_equi = 1;
          me.tipoServSolicitud.tipo_remolque =
            me.tipoServSolicitud.tipo_vh_equi_obj.id;
        }
      } else {
        me.tipoServSolicitud.tipo_vh_equi =
          me.tipoServSolicitud.tipo_vh_equi_obj.numeracion;
        me.tipoServSolicitud.tipo_remolque = null;
      }
    },
  },
};
</script>
